import React, { useContext } from 'react';
import {
  Flex, Box, Card, Text,
} from 'rebass';
import { Link } from 'gatsby';
import slugify from 'slugify';
import CardFood from './CardFood';
import RestaurantLogo from './RestaurantLogo';
import { Colors } from '../theme';
import Distance from './Distance';
import RestaurantOpenClose from './RestaurantOpenClose';
import UserLocationContext from '../context/UserLocationContext';

const CardFoodRestaurant = (props) => {
  const { Restaurant } = props;
  const { latitude, longitude } = useContext(UserLocationContext);
  return (
    <Card style={{ border: '1px solid #ddd', height: '100%' }} bg="#fff">
      <Link
        style={{ textDecoration: 'none' }}
        to={`/ristoranti/${slugify(Restaurant.name.toLowerCase())}`}
      >
        <Box style={{ borderBottom: '1px solid #eee' }} p={3}>
          <Card>
            <Flex flexDirection="row" alignItems="center">
              <RestaurantLogo size={[40]} imageUrl={Restaurant.logo} />
              <Flex mx={10} justifyContent="center" flexDirection="column">
                <Text lineHeight={1.4} color={Colors.BLACK} fontSize={[2, 3]}>
                  {Restaurant.name}
                </Text>
                <Text lineHeight={1.4} color={Colors.GREY} fontSize={1}>
                  {Restaurant.cuisineType}
                </Text>
                <Flex flexDirection="row">
                  {latitude && longitude && (
                    <>
                      <Distance fontSize={1} restaurantLocation={Restaurant.location} />
                      <Text color={Colors.GREY} mr={1}>
                        -
                      </Text>
                    </>
                  )}
                  <RestaurantOpenClose
                    withIcon={false}
                    tradingHours={Restaurant.tradingHours}
                    fontSize={1}
                  />
                </Flex>
              </Flex>
            </Flex>
          </Card>
        </Box>
        <CardFood {...props} />
      </Link>
    </Card>
  );
};

export default CardFoodRestaurant;
