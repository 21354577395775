import React from 'react';
import PropTypes from 'prop-types';
import {
  Flex, Box, Text,
} from 'rebass';
import { FaStar, FaExclamationTriangle } from 'react-icons/fa';
import { Colors } from '../theme';
import ChefHat from '../images/chef_hat.inline.svg';

const CardFood = (props) => {
  const {
    imageUrl, title, description, price, type, dishOfTheDay,
  } = props;
  return (
    <Flex flexDirection="column">
      <div style={{ position: 'relative' }}>
        <img
          alt={title}
          src={imageUrl}
          style={{
            width: '100%',
            height: '100%',
            backgroundColor: Colors.MEDIUM_GREY,
            margin: 0,
            borderBottom: '1px solid #eee',
          }}
        />
        {type === 'star' && (
          <Flex
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            backgroundColor="rgba(0,0,0,.7)"
            style={{
              alignSelf: 'flex-start',
              borderTopLeftRadius: 4,
              borderBottomLeftRadius: 4,
              position: 'absolute',
              bottom: 25,
              right: 0,
            }}
            px={2}
            py={1}
          >
            <Box py={1}>
              <Text fontSize={2} color="#fff" mr={2}>
                Consigliato dallo Chef
              </Text>
            </Box>
            <ChefHat
              style={{ marginRight: 5, width: 14 }}
              fill="#fff"
            />
          </Flex>
        )}
        {type === 'new' && (
          <Flex
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            backgroundColor="rgba(0,0,0,.7)"
            style={{
              alignSelf: 'flex-start',
              borderTopLeftRadius: 4,
              borderBottomLeftRadius: 4,
              position: 'absolute',
              bottom: 25,
              right: 0,
            }}
            px={2}
            py={1}
          >
            <Box py={1}>
              <Text fontSize={2} color="#fff" mr={2}>
                Novità
              </Text>
            </Box>
            <FaStar
              style={{ marginRight: 5, width: 14, marginBottom: 2 }}
              fill="#fff"
            />
          </Flex>
        )}
        {dishOfTheDay && (
          <Flex
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            backgroundColor="rgba(0,0,0,.7)"
            style={{
              alignSelf: 'flex-start',
              borderTopLeftRadius: 4,
              borderBottomLeftRadius: 4,
              position: 'absolute',
              bottom: 25,
              right: 0,
            }}
            px={2}
            py={1}
          >
            <Flex flexDirection="column" justifyContent="center" py={1}>
              <Text color="#f57c00" fontSize={2} mr={2}>
                Esempio di piatto del giorno,
              </Text>
              <Text color="#f57c00" fontSize={2} mr={2}>
                non sempre disponibile
              </Text>
            </Flex>
            <FaExclamationTriangle
              style={{ marginRight: 5, width: 14, marginBottom: 2 }}
              fill="#f57c00"
            />
          </Flex>
        )}
      </div>
      <Box width={1} p={3}>
        <Flex mb={2} flexDirection="row">
          <Text flex="auto" fontWeight="600" fontSize={2} color={Colors.RED}>
            {title}
          </Text>
          {price > 0 && (
            <Text width="96px" fontWeight="600" textAlign="right" color={Colors.RED} fontSize={2}>
              {`${Number(price).toFixed(2)} CHF`}
            </Text>
          )}
        </Flex>
        <Text color={Colors.BLACK} lineHeight={1.4} fontSize={1}>{description}</Text>
      </Box>
    </Flex>
  );
};

CardFood.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  description: PropTypes.string,
  price: PropTypes.number,
  dishOfTheDay: PropTypes.bool.isRequired,
};

export default CardFood;
