import React, { useContext, useState } from 'react';
import {
  Flex, Box, Text, Button,
} from 'rebass';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { format } from 'date-fns/esm';
import { FaCog } from 'react-icons/fa';
import Layout from '../components/Layout';
import CardFoodRestaurant from '../components/CardFoodRestaurant';
import useLocateMe from '../components/useLocateMe';
import LocateMeButton from '../components/LocateMeButton';
import { Colors } from '../theme';
import SEO from '../components/SEO';
import UserLocationContext from '../context/UserLocationContext';

const LIST_FOOD_IMAGES = gql`
  query listFoodImages(
    $TagId: ID
    $city: City!
    $userLatitude: Float
    $userLongitude: Float
    $offset: Int
    $limit: Int
    $day: String
  ) {
    listFoodImages(
      TagId: $TagId
      city: $city
      userLatitude: $userLatitude
      userLongitude: $userLongitude
      offset: $offset
      limit: $limit
    ) {
      id
      type
      imageUrl
      title
      price
      description
      photoBy
      Restaurant {
        id
        name
        logo
        tradingHours(day: $day)
        location {
          coordinates
        }
        cuisineType
      }
    }
  }
`;

const CategoryPage = ({ pageContext: { name, id } }) => {
  const [noMoreResults, setNoMoreResults] = useState(false);
  const [loadingLocation, locateUser] = useLocateMe();
  const { latitude, longitude } = useContext(UserLocationContext);
  if (!name) {
    return null;
  }
  return (
    <Layout headerRight="restaurants">
      <SEO
        pageTitle={`Ricerca per categoria: ${name} - Mangiami`}
        pageDescription={`Scegli cosa e dove mangiare esplorando la categoria ${name}.`}
        pageImage="https://d12unveku4b8zl.cloudfront.net/restaurants/lugano/0000%20static/mangiami%20thumbnail%20-%20final.png"
        pageUrl={`/esplora/${name}`}
      />
      <Box bg="#f2f2f2">
        <Flex flexDirection="column" mx="auto" style={{ maxWidth: 1200 }}>
          <Query
            query={LIST_FOOD_IMAGES}
            variables={{
              TagId: id,
              city: 'lugano',
              offset: 0,
              limit: 9,
              userLatitude: latitude,
              userLongitude: longitude,
            }}
            fetchPolicy="cache-and-network"
            notifyOnNetworkStatusChange
          >
            {({
              loading, error, data, refetch, fetchMore, networkStatus,
            }) => {
              if (loadingLocation || (loading && networkStatus === 1)) {
                return (
                  <Flex
                    py={[4, 6]}
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <FaCog size={28} className="icon-spin" />
                    <Text py={3}>Caricamento</Text>
                  </Flex>
                );
              }
              if (error) {
                return (
                  <Flex style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                    <Text>Errore durante il caricamento foto</Text>
                    <Button varint="primary" onClick={() => refetch().catch(() => {})}>
                      <Text color="#fff">Riprova</Text>
                    </Button>
                  </Flex>
                );
              }
              const { listFoodImages } = data;
              return (
                <Flex width={1} flexDirection="column" flexWrap="wrap">
                  {!(latitude && longitude) && (
                    <Box py={3}>
                      <Flex
                        py={3}
                        flexWrap="wrap"
                        flexDirection="row"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Text mb={[2, 0]} width={[1, 'auto']} textAlign="center">
                          Vuoi vedere i piatti più vicini a te?
                        </Text>
                        <LocateMeButton
                          onClick={locateUser}
                          mx={2}
                          variant="outline"
                          loading={loadingLocation}
                        />
                      </Flex>
                      <Text textAlign="center" fontSize={1}>
                        (di default i piatti sono ordinati per distanza da Piazza Riforma, 6900
                        Lugano)
                      </Text>
                    </Box>
                  )}
                  <Text py={[3, 4]} fontSize={[4, 5]} textAlign="center" width={1}>
                    Categoria: {name}
                  </Text>
                  <Flex flexDirection="row" flexWrap="wrap" my={-1}>
                    {listFoodImages.map(FoodImage => (
                      <Box
                        key={FoodImage.id}
                        px={[2, 3]}
                        py={[1, 3]}
                        width={[1, 1 / 2, 1 / 2, 1 / 3]}
                      >
                        <CardFoodRestaurant {...FoodImage} />
                      </Box>
                    ))}
                  </Flex>
                  {loading && networkStatus !== 1 ? (
                    <Flex py={3} flexDirection="row" justifyContent="center">
                      <FaCog size={24} className="icon-spin" />
                    </Flex>
                  ) : (
                    !noMoreResults && listFoodImages.length % 9 === 0 && (
                      <Flex py={3} flexDirection="row" justifyContent="center">
                        <Button
                          onClick={() => {
                            fetchMore({
                              variables: {
                                offset: listFoodImages.length,
                              },
                              updateQuery: (prev, { fetchMoreResult }) => {
                                if (
                                  !fetchMoreResult
                                  || fetchMoreResult.listFoodImages.length === 0
                                ) {
                                  setNoMoreResults(true);
                                  return prev;
                                }
                                return {
                                  ...prev,
                                  listFoodImages: [
                                    ...prev.listFoodImages,
                                    ...fetchMoreResult.listFoodImages,
                                  ],
                                };
                              },
                            });
                          }}
                          variant="outline"
                        >
                          Carica più piatti
                        </Button>
                      </Flex>
                    )
                  )}
                </Flex>
              );
            }}
          </Query>
          <Box py={5}>
            <Text color={Colors.GREY} textAlign="center">
              I contenuti presenti sulla Piattaforma, in particolare le fotografie dei cibi e le
              schede dei ristoranti, sono pubblicati a titolo meramente informativo e senza
              garanzia, per cui alcuni cibi, bevande e/o servizi (incluso il prezzo) potrebbero non
              essere sempre aggiornati e/o disponibili.
            </Text>
          </Box>
        </Flex>
      </Box>
    </Layout>
  );
};

export default CategoryPage;
